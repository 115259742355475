import * as React from "react"
import {
  jsx,
  Link as TLink,
  Box,
  Grid,
  Text,
  Heading,
  Paragraph,
} from "theme-ui"
import { Link } from "gatsby"

const BlogListItem = ({ post }) => (
  <Box pl={[2]} pr={[2]} mt={[5]}>
    <TLink as={Link} to={post.fields.slug} variant="links.heading">
      <Heading as="h5" mb={3} mt={0} variant="styles.h5">
        {post.frontmatter.title}
      </Heading>
    </TLink>
    <Text
      as={Paragraph}
      sx={{ lineHeight: 2.5, margin: `0`, display: ["none", "inline"] }}
    >
      {post.frontmatter.date}
    </Text>
    <TLink as={Link} to={post.fields.slug} variant="links.text">
      <Text variant="p">
        <p
          dangerouslySetInnerHTML={{
            __html: post.frontmatter.description || post.excerpt,
          }}
          itemProp="description"
        />
      </Text>
    </TLink>
  </Box>
)

export default BlogListItem
