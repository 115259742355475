/** @jsx jsx */
import { jsx } from "theme-ui"
import * as React from "react"
import { graphql } from "gatsby"
import TagLine from "../components/tagLine"
import HeroCard from "../components/heroCard"
import Title from "../components/title"
import BlogListItem from "../components/blogListItem"
import CardListItem from "../components/cardListItem"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Box } from "theme-ui"
import Parser from "html-react-parser"

const AllPosts = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMdx.nodes

  return (
    <Layout location={location} title={siteTitle} wrapper="global-wrapper">
      <Seo title="All posts" />
      <Title text="All Posts"></Title>
      <Box variant="wrapper.home">
        {posts.map(post => {
          return <BlogListItem post={post} />
        })}
      </Box>
    </Layout>
  )
}

export default AllPosts

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      filter: { fileAbsolutePath: { regex: "/content/blog/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          featuredImage {
            image {
              relativePath
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            credit
            creditUrl
          }
        }
      }
    }
  }
`
